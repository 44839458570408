var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c("div", { staticClass: "d-flex flex-column secondary--text" }, [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v("Requests"),
              ]),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "ml-1",
                                attrs: { text: "", icon: "" },
                                on: { click: _vm.fetchRequests },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("refresh")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_c("span", [_vm._v("refresh")])]
              ),
            ],
            1
          ),
          _vm.fetchingUserOwnRequests || _vm.fetchingUserToApproveRequests
            ? _c("span", { staticClass: "caption text-uppercase" }, [
                _vm._v("Fetching requests..."),
              ])
            : _vm.selectedTab === _vm.tabOptions.OWN_REQUESTS
            ? _c("span", { staticClass: "caption text-uppercase" }, [
                _vm._v(_vm._s(_vm.userRequests.own.length) + " requests found"),
              ])
            : _vm.selectedTab === _vm.tabOptions.REQUESTS_TO_APPROVE
            ? _c("span", { staticClass: "caption text-uppercase" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.userRequests.toApprove.length) +
                    " requests found "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-tabs",
            {
              staticClass: "mt-5",
              model: {
                value: _vm.selectedTab,
                callback: function ($$v) {
                  _vm.selectedTab = $$v
                },
                expression: "selectedTab",
              },
            },
            [
              _c("v-tab", [_vm._v("Requests submitted")]),
              _c("v-tab", [_vm._v("Requests to approve")]),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "mt-7",
              model: {
                value: _vm.selectedTab,
                callback: function ($$v) {
                  _vm.selectedTab = $$v
                },
                expression: "selectedTab",
              },
            },
            [
              _c(
                "v-tab-item",
                [
                  _c("requestsList", {
                    attrs: {
                      requestType: _vm.requestTypes.OWN_REQUESTS,
                      requestsData: _vm.userRequests.own,
                      fetchingRequests: _vm.fetchingUserOwnRequests,
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-tab-item",
                [
                  _c("requestsList", {
                    attrs: {
                      requestType: _vm.requestTypes.REQUESTS_TO_APPROVE,
                      requestsData: _vm.userRequests.toApprove,
                      fetchingRequests: _vm.fetchingUserToApproveRequests,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }